import React, { ReactNode, useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import EvoLoader from './EvoLoader';
//@ts-ignore
import * as cbconn from 'corebos-ws-lib/WSClientm';


const EvoForm = ({ className, children, defaultValues, onSubmit, afterSubmit, moduleName, moduleFields = [] }: { className?: string, children: ReactNode, defaultValues?: any, onSubmit?: any, afterSubmit?: any, moduleName: string, moduleFields?: any[] }) => {

    const methods = useForm({ defaultValues });
    const { handleSubmit, reset } = methods;
    const [isSaving, setIsSaving] = useState<boolean>(false);


    useEffect(() => {
        reset(defaultValues);
    }, [defaultValues, reset])

    const update = async (values: any) => {
        setIsSaving(true);
        const result: any = await cbconn.doUpdate(moduleName, values).catch((err: any) => {
            toast.error(err, {position: 'bottom-center'});
            return err;
        }).finally(() => {
            setIsSaving(false);
        });
        if (result?.id){
            toast.success('Record updated', {position: 'bottom-center'});
            if(afterSubmit){
                afterSubmit(result);
            }
        }
    }

    const create = async (values: any) => {
        setIsSaving(true);
        const result: any = await cbconn.doCreate(moduleName, values).catch((err: any) => {
            toast.error(err, {position: 'bottom-center'});
            return err;
        }).finally(() => {
            setIsSaving(false);
        });
        if (result?.id){
            toast.success('Record created', {position: 'bottom-center'});
            if(afterSubmit){
                afterSubmit(result);
            }
        } 
    }

    const handleFormSubmit = handleSubmit((data: any) => {
        if(onSubmit){
            onSubmit(data);
        } else if(data.id){
                update(data);
        } else {
            create(data);
        }
    });

    return (
        <FormProvider {...methods}>
            <form  onSubmit={handleFormSubmit} className={className} > 
                { isSaving ? <EvoLoader /> : <>{children}</> } 
            </form>
        </FormProvider>
    );

}

export default EvoForm;