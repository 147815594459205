import { useEffect, useState, ReactNode } from "react";
import dataProvider from 'react-admin-corebos/corebosServerProvider';
import { DataProvider } from "@plasmicapp/host";
import { ToastContainer, toast } from 'react-toastify';
import { Html5Qrcode } from "html5-qrcode";
//@ts-ignore
import * as cbconn from 'corebos-ws-lib/WSClientm';


const SEARCH_RESULT_PAGINATION = {
  page: 1,
  pageSize: 20
}

const EvoSearch = ({ className, searchResultSlot, searchInputValue, moduleName, searchField, singleResult = false, qrReaderElementId = 'reader', scanQrCode = false} : { className?: string, searchResultSlot?: ReactNode, searchInputValue?: string, moduleName: string, searchField?: string, singleResult?: boolean, qrReaderElementId?: string, scanQrCode?: boolean}) => {

  const [searchResult, setSearchResult] = useState<any>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [html5QrCode, setHtml5QrCode] = useState<Html5Qrcode | null>(null);
  const [isScanning, setIsScanning] = useState<boolean>(false);
  const [searchText, setSearchText] = useState<any>('');


  useEffect(() => {
    try {
      const html5QrCodeEle = new Html5Qrcode(qrReaderElementId);
      setHtml5QrCode(html5QrCodeEle);
    } catch (error: any) {
      console.log('error...', error)
    }
  }, [qrReaderElementId]);

  useEffect(() => {
    setIsScanning(scanQrCode);
  }, [scanQrCode]);

    useEffect(() => {
      const startQrScanning = async () => {
        const cameraId = await getCamera() ?? {};
        if(cameraId && html5QrCode){
          html5QrCode.start(
            cameraId, 
            {
              fps: 10,    // Optional, frame per seconds for qr code scanning
              qrbox: { width: 250, height: 250 }  // Optional, if you want bounded box UI
            },
            (decodedText: any) => {
              setSearchText(decodedText);
              stopQrScanning();
            },
            (errorMessage: string) => {
              // parse error, ignore it.
            })
          .catch((err: any) => {
            toast.error(err, {position: 'bottom-center'});
          }); 
        }
      }
      
      const stopQrScanning = () => {
        if(html5QrCode?.isScanning){
          html5QrCode.stop().then((ignore: any) => {
            // QR Code scanning is stopped.
          }).catch((err: any) => {
            toast.error(err, {position: 'bottom-center'});
          }).finally(() => {
            setIsScanning(false);
          })
        } 
      }

      if(isScanning){
        startQrScanning();
      } else {
        stopQrScanning();
      }
    }, [html5QrCode, isScanning]);

    useEffect(() => {
      const getDescribeData = async (resource: string) => {
        setIsLoading(true);
        const moduleDescribe: any = await cbconn.doDescribe(resource).catch((err: any) => {
            return null;
        });
        setIsLoading(false);
        if(moduleDescribe){
            const data: any = {};
            data[resource] = moduleDescribe;
            const windowObj: any = window;
            if (windowObj.coreBOS === undefined) {
                windowObj.coreBOS = {};
            }
            windowObj.coreBOS.Describe = windowObj.coreBOS?.Describe ?? {};
            windowObj.coreBOS.Describe[resource] = moduleDescribe;
        }
      }
      if(moduleName && typeof window !== "undefined"){
        const windowObj: any = window;
        if (windowObj.coreBOS === undefined) {
            windowObj.coreBOS = {};
        }
        getDescribeData(moduleName);
      }
    }, [moduleName]);

    useEffect(() => {
      setSearchText(searchInputValue)
    }, [searchInputValue]);

    useEffect(() => {
      if (moduleName) {
        let currentFilter: any = {};
        if(searchText){
          if(searchField){
            currentFilter[`${searchField}`] = searchText;
          } else {
            const labelFields: string = searchField ?? getSearchField(moduleName);
            if(labelFields){
              currentFilter[`cblistsearch_${moduleName}`] = searchText;
            }
          }
          loadItems(SEARCH_RESULT_PAGINATION.page, SEARCH_RESULT_PAGINATION.pageSize , moduleName, currentFilter, singleResult);
        }
      }
    }, [searchText, moduleName, searchField, singleResult]);


    const loadItems = (pageNumber: number, pageSize: number, module: string = '', filters: any = {}, signle = false) => {
      setIsLoading(true);
      dataProvider.getList(module, {pagination: {page: pageNumber, perPage: pageSize}, filter: filters, sort: {}}).then((res: {data: any[], total: number}) => {
        const { data, total } = res;
        const result = signle && total > 0 ? data[0] : data;
        setSearchResult(result);
      }).catch((err: any) => {
        console.log(err)
      }).finally(() => {
        setIsLoading(false);
      });
    }

    const getSearchField = (moduleName: string = ''): string => {
      const window_: any = window;
      const moduleDescribe: any = window_?.coreBOS?.Describe[moduleName] ?? {};
      const labelFields: string = moduleDescribe?.labelFields ?? '';
      return labelFields?.split(',')[0] ?? ''
    }

    const getCamera = () => {
      return Html5Qrcode.getCameras().then(devices => {
        if (devices?.length) {
          const cameraId = devices[0].id;
          return cameraId;
        }
      }).catch(err => {
        toast.error(err, {position: 'bottom-center'});
        return null;
      });
    }
  
    return (
      <div className={className}> 
        <DataProvider name="evoSearchProps" data={{searchModule: moduleName, isLoading: isLoading, searchResult: searchResult}}>
          {searchResultSlot}
        </DataProvider>
        <ToastContainer />
      </div>
    )

}

export default EvoSearch;
