import { PlasmicCanvasHost, registerComponent } from '@plasmicapp/react-web/lib/host'
import BaseLayout from '../components/BaseLayout';
import EvoForm from '../components/EvoForm';
import EvoFormField from '../components/EvoFormField';
import TextInput from '../components/CustomTextInput';
import EvoDetailsView from '../components/EvoDetailsView';
import EvoList from '../components/EvoList';
import EvoSearch from '../components/EvoSearch';


registerComponent(BaseLayout, {
  name: 'BaseLayout',
  importPath: "./src/components/BaseLayout",
  isDefaultExport: true,
  providesData: true,
  props: {
    children: {
      type: "slot",
    },
    appName: {
      type: "string",
      defaultValue: "Evo UI",
    },
    showHeader: {
      type: "boolean",
      defaultValue: true,
    },
    showBackButton: {
      type: "boolean",
      defaultValue: true
    },
    allowAuthentication: {
      type: "boolean",
      defaultValue: false,
    },
    evolutivoWsUrl: {
      type: "string",
      defaultValue: "https://rubber-road.evolutivo.it/",
      description: "Change this to your API url you want to use"
    },
    loginPath: {
      type: "string",
      description: "Leave this empty to use default Evolutivo Login component"
    },
    moduleName: {
      type: "string",
      defaultValue: ''
    },
    containerBgStyle: {
      type: "object",
      defaultValue: {
        "backgroundImage": "url(https://app-rubber.evolutivo.it/rubberpwaBg.png)",
        "backgroundSize": "cover",
        "backgroundPosition": "center"
      },
      description: "This will apply in this page/component as well as login component"
    },
    applyContainerBgStyle: {
      type: "boolean",
      defaultValue: false,
      description: "Set this to true if you want to apply page/component background specified in `containerBgStyle`"
    },
  }
});

registerComponent(EvoList, {
    name: 'EvoList',
    importPath: "./src/components/EvoList",
    isDefaultExport: true,
    providesData: true,
    props: {
      listItemsSlot: {
        type: "slot",
        description: "Use this slot to display search result using the data provided in context evoListProps as records"
      },
      moduleName: {
        type: "string",
        description: "Module name"
      },
      searchInputValue: {
        type: "string",
      },
      perPage: {
        type: "number",
        defaultValue: 20,
      },
      page: {
        type: "number",
        defaultValue: 1,
      },
      paginatingStyle: {
        type: "choice",
        options: [
          {label: "buttons", value: "buttons"},
          {label: "onscroll", value: "onscroll"},
        ],
        defaultValue: "buttons"
      },
      defaultFilter: {
        type: "string",
        defaultValue: '',
        description: `Key-value pairs json data i.e  '{"vehicle_stato": "Pending"}'`
      }
    }
});

// searchResultSlot, searchInputValue, moduleName, searchField, singleResult = false, withQrCode = false
registerComponent(EvoSearch, {
  name: 'EvoSearch',
  importPath: "./src/components/EvoSearch",
  isDefaultExport: true,
  providesData: true,
  props: {
    searchResultSlot: {
      type: "slot",
      description: "Use this slot to display search result using the data provided in context evoSearchProps as searchResult"
    },
    moduleName: {
      type: "string",
      description: "Module name"
    },
    searchField: {
      type: "string",
    },
    searchInputValue: {
      type: "string",
    },
    singleResult: {
      type: "boolean",
      defaultValue: false,
    },
    qrReaderElementId: {
      type: "string",
      defaultValue: 'reader',
    },
    scanQrCode: {
      type: "boolean",
      defaultValue: false,
    },
  },
});

registerComponent(EvoForm, {
  name: 'EvoForm',
  displayName: "Evolutivo Form",
  importPath: "./src/components/EvoForm",
  isDefaultExport: true,
  providesData: true,
  props: {
    children: {
      type: "slot",
      hidePlaceholder: true,
    },
    moduleName: {
      type: "string",
      defaultValue: ''
    },
    moduleFields: {
      type: "array",
      defaultValue: [],
    },
    defaultValues: {
      type: "object",
      defaultValue: {}
    }
  }
});

registerComponent(EvoFormField, {
  name: 'EvoFormField',
  displayName: "Evolutivo Form Field",
  importPath: "./src/components/EvoFormField",
  isDefaultExport: true,
  props: {
    children: {
      type: "slot",
      hidePlaceholder: true,
    },
    name: {
      type: "string",
      defaultValue: "",
    }
  }
});
registerComponent(TextInput, {
  name: 'EvoTextinput',
  displayName: "Evolutivo Text Input",
  importPath: "./src/components/EvoInput",
  isDefaultExport: false,
  props: {
    value: {
      type: "string",
      defaultValue: "",
    },
    label: {
      type: "string",
      defaultValue: ""
    },
    uitype: {
      type: "string",
    },
    required: {
      type: "boolean",
      defaultValue: false
    },
    isEditable: {
      type: "boolean",
      defaultValue: true
    },
    type: {
      type: "choice",
      options: [
        {label: "Text", value: "text"},
        {label: "Password", value: "password"},
        {label: "Hidden", value: "hidden"},
        {label: "Number", value: "number"},
        {label: "Date", value: "date"},
        {label: "Datetime-local", value: "datetime-local"},
        {label: "Time", value: "time"},
        {label: "Email", value: "email"},
        {label: "Telephone", value: "tel"}
      ],
      defaultValue: "text"
    }
  }
});

registerComponent(EvoDetailsView, {
  name: 'EvoDetailsView',
  displayName: "Evo Details View",
  importPath: "./src/components/EvoDetailsView",
  isDefaultExport: true,
  providesData: true,
  props: {
    children: {
      type: "slot",
      hidePlaceholder: true,
    },
    recordId: {
      type: "string",
      defaultValue: "",
    },
    moduleName: {
      type: "string",
      defaultValue: ''
    },
  }
});

const PlasmicHost = () => {
  return (
    <PlasmicCanvasHost />
  )
}

export default PlasmicHost;
