import { useEffect, useState, ReactNode } from "react";
import dataProvider from 'react-admin-corebos/corebosServerProvider';
import InfiniteScroll from "react-infinite-scroll-component";
import { DataProvider } from "@plasmicapp/host";
import { ToastContainer } from 'react-toastify';


const EvoList = ({ className, listItemsSlot, searchInputValue = '', moduleName, paginatingStyle, perPage = 20, page = 1, defaultFilter = '' }: { className?: string, listItemsSlot?: ReactNode, searchInputValue?: string, moduleName: string, paginatingStyle: string, perPage: number, page: number, defaultFilter?: string }) => {

  const [currentPage, setCurrentPage] = useState<number>(page);
  const [total, setTotal] = useState<number>(0);
  const [records, setRecords] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [filters, setFilters] = useState<string>('');
  const [searchText, setSearchText] = useState<string>('');



  useEffect(() => {
    setCurrentPage(page)
  }, [page]);

  useEffect(() => {
    setSearchText(searchInputValue)
  }, [searchInputValue]);

  useEffect(() => {
    if (moduleName) {
      let currentFilter: any = {};
      if (searchText) {
        const labelFields: string = getSearchField(moduleName);
        if (labelFields) {
          currentFilter[`cblistsearch_${moduleName}`] = searchText;
          setFilters(JSON.stringify(currentFilter));
        }
      } else {
        setFilters('');
      }
    }
  }, [moduleName, searchText]);

  useEffect(() => {
    let currentFilter: any = {};
    try {
      currentFilter = filters ? JSON.parse(filters) : {};
      currentFilter = defaultFilter ? { ...currentFilter, ...JSON.parse(defaultFilter) } : currentFilter;
    } catch (error) {

    }
    loadItems(currentPage, perPage ?? 10, moduleName, currentFilter, paginatingStyle);
  }, [currentPage, defaultFilter, filters, moduleName, paginatingStyle, perPage]);


  const loadItems = (pageNumber: number, pageSize: number, module: string = '', filters: any = {}, paginationStyle = '') => {
    setIsLoading(true);
    dataProvider.getList(module, { pagination: { page: pageNumber, perPage: pageSize }, filter: filters, sort: {} }).then((res: { data: any[], total: number }) => {
      const { data, total } = res;
      setTotal(total);
      if (paginationStyle === 'onscroll') {
        setRecords((prevData) => {
          const newData = [...prevData, ...data];
          return newData;
        });
      } else {
        setRecords(data);
      }
    }).catch((err: any) => {
      console.log(err)
    }).finally(() => {
      setIsLoading(false);
    });
  }

  const getSearchField = (moduleName: string = ''): string => {
    const window_: any = window;
    const moduleDescribe: any = window_?.coreBOS?.Describe[moduleName] ?? {};
    const labelFields: string = moduleDescribe?.labelFields ?? '';
    return labelFields?.split(',')[0] ?? ''
  }

  const onScroll = (pageNumber: number) => {
    setCurrentPage(pageNumber + 1);
  }

  const refresh = () => {
    setTotal(0);
    setCurrentPage(1);
  }


  return (
    <div className={className}>
      <DataProvider name="evoListProps" data={{ moduleName: moduleName, isLoading: isLoading, records: records, total: total }}>
        {paginatingStyle === 'onscroll'
          ? <InfiniteScroll
            dataLength={records.length}
            next={() => onScroll(currentPage)}
            hasMore={records.length !== total}
            loader={isLoading ? <h4 style={{ textAlign: 'center' }}>Loading...</h4> : <></>}
            pullDownToRefresh={true}
            refreshFunction={refresh}
          >
            <>  {listItemsSlot} </>
          </InfiniteScroll>
          : <>  {listItemsSlot} </>
        }
      </DataProvider>
      <ToastContainer />
    </div>
  )

}

export default EvoList;
