
import { Route, Routes } from 'react-router-dom';
import PlasmicHost from './pages/PlasmicHost';
import TestComponent from './components/TestComponent';



const App = ()  => {

  return (
    <div className="App">
      <Routes>
        <Route  path='/plasmic-host' element={<PlasmicHost />} />
        <Route  path='/' element={<TestComponent />} />
      </Routes>
    </div>
  );
}

export default App;
  