// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type Icon10IconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function Icon10Icon(props: Icon10IconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      fill={"currentColor"}
      viewBox={"0 0 16 16"}
      height={"1em"}
      className={classNames("plasmic-default__svg", className)}
      style={style}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M14.25 1.5h-2.12c-.35 0-.62.28-.62.62s.28.62.62.62h2.12v2.52c0 .35.28.62.62.62s.62-.28.62-.62V2.75c0-.69-.56-1.25-1.25-1.25zM1.12 5.89c.35 0 .62-.28.62-.62V2.75h2.15c.35 0 .62-.28.62-.62s-.28-.62-.62-.62H1.75C1.06 1.51.5 2.07.5 2.76v2.52c0 .35.28.62.62.62zm2.77 7.36H1.75v-2.52c0-.35-.28-.62-.62-.62s-.62.28-.62.62v2.52c0 .69.56 1.25 1.25 1.25h2.13c.35 0 .62-.28.62-.62s-.28-.62-.62-.62zm10.99-3.14c-.35 0-.62.28-.62.62v2.52h-2.12c-.35 0-.62.28-.62.62s.28.62.62.62h2.12c.69 0 1.25-.56 1.25-1.25v-2.52c0-.35-.28-.62-.62-.62z"
        }
      ></path>

      <path
        d={
          "M6.12 3.55H4.03c-.69 0-1.25.56-1.25 1.25v1.54c0 .69.56 1.25 1.25 1.25h2.09c.69 0 1.25-.56 1.25-1.25V4.8c0-.69-.56-1.25-1.25-1.25zm0 2.79H4.03V4.8h2.09v1.54zm7.1 4.86V9.66c0-.69-.56-1.25-1.25-1.25H9.88c-.69 0-1.25.56-1.25 1.25v1.54c0 .69.56 1.25 1.25 1.25h2.09c.69 0 1.25-.56 1.25-1.25zM9.88 9.66h2.09v1.54H9.88V9.66zM8.63 5.85h2.52V7.1H8.63zm0-1.8h4.59V5.3H8.63zM4.85 8.9h2.52v1.25H4.85zm-2.07 1.8h4.59v1.25H2.78z"
        }
      ></path>
    </svg>
  );
}

export default Icon10Icon;
/* prettier-ignore-end */
