import { DataProvider } from '@plasmicapp/host'
import React, { ReactNode, useEffect, useState } from 'react'
import EvoLoader from './EvoLoader';
//@ts-ignore
import * as cbconn from 'corebos-ws-lib/WSClientm';



const EvoDetailsView = ({ className, children, moduleName, recordId }: { className?: string, children: ReactNode, moduleName?: string, recordId?: string }) => {

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [record, setRecord] = useState<any>({});


    useEffect(() => {
        const retreiveRecord = async (id: string) => {
            setIsLoading(true);
            const result: any = await cbconn.doInvoke('retrieve', { id: id }, 'POST').catch((err: any) => {
                return err;
            });
            setIsLoading(false);
            if (result?.id) {
                setRecord(result);
            }
        }
        if(recordId && moduleName){
            retreiveRecord(recordId);
        }
    }, [moduleName, recordId])
    

    return (
        <div className={className}>
            <DataProvider name="recordInfo" data={{ record: record, moduleName: moduleName }}>
                {isLoading ? <EvoLoader className={className} /> :  <>{children}</>}
            </DataProvider>
        </div>
    )

}

export default EvoDetailsView