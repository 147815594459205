// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type Icon6IconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function Icon6Icon(props: Icon6IconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      viewBox={"0 0 24 24"}
      fill={"none"}
      height={"1em"}
      className={classNames("plasmic-default__svg", className)}
      style={style}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={"M3 3l18 18"}
        stroke={"currentColor"}
        strokeWidth={"2"}
        strokeLinecap={"round"}
        strokeLinejoin={"round"}
      ></path>

      <path
        fillRule={"evenodd"}
        clipRule={"evenodd"}
        d={
          "M4.192 3.606A2.995 2.995 0 003 6v12a3 3 0 003 3h12c.981 0 1.853-.471 2.4-1.2a1.007 1.007 0 01-.107-.093l-1.356-1.356A1 1 0 0118 19h-2.333v-3.333h.586l-2.586-2.586v.586h-3.334v-3.334h.586L8.333 7.748v.585H5V6a1 1 0 01.65-.937L4.191 3.606zM21 17.586V6a3 3 0 00-3-3H6.414l3.92 3.92V5h3.333v3.333h-1.92l3.92 3.92v-1.92H19v3.334h-1.92L21 17.586zm-5.333-9.253V5H18a1 1 0 011 1v2.333h-3.333zm-2 7.334V19h-3.334v-3.333h3.334zm-5.334 0V19H6a1 1 0 01-1-1v-2.333h3.333zm0-2H5v-3.334h3.333v3.334z"
        }
        fill={"currentColor"}
      ></path>
    </svg>
  );
}

export default Icon6Icon;
/* prettier-ignore-end */
