// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PlasmicEvoLoader_loaderContainer__dZH3T {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: center;
  justify-self: flex-start;
  min-width: 0;
}
.PlasmicEvoLoader_loaderContent__Yubwl {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.PlasmicEvoLoader_img__2\\+wj5 {
  position: relative;
  object-fit: none;
  max-width: 100%;
  margin-top: -150px;
}
.PlasmicEvoLoader_img__2\\+wj5 > picture > img {
  object-fit: none;
}
.PlasmicEvoLoader_loadingTexts__3qrz3 {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  margin-top: -70px;
  min-width: 0;
  padding: 8px;
}
.PlasmicEvoLoader_loadingPrimaryText__UJSfm {
  width: 100%;
  height: auto;
  max-width: 100%;
  text-align: center;
  font-weight: 700;
  font-size: 16px;
  min-width: 0;
}
.PlasmicEvoLoader_loadingSecondaryText__r9hri {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  text-align: center;
  font-weight: 700;
  font-size: 14px;
  min-width: 0;
}
`, "",{"version":3,"sources":["webpack://./src/components/plasmic/evo_components/PlasmicEvoLoader.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,kBAAkB;EAClB,WAAW;EACX,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,wBAAwB;EACxB,YAAY;AACd;AACA;EACE,aAAa;EACb,kBAAkB;EAClB,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;EACvB,WAAW;EACX,YAAY;EACZ,eAAe;EACf,YAAY;AACd;AACA;EACE,kBAAkB;EAClB,gBAAgB;EAChB,eAAe;EACf,kBAAkB;AACpB;AACA;EACE,gBAAgB;AAClB;AACA;EACE,aAAa;EACb,kBAAkB;EAClB,sBAAsB;EACtB,mBAAmB;EACnB,2BAA2B;EAC3B,WAAW;EACX,YAAY;EACZ,eAAe;EACf,iBAAiB;EACjB,YAAY;EACZ,YAAY;AACd;AACA;EACE,WAAW;EACX,YAAY;EACZ,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,eAAe;EACf,YAAY;AACd;AACA;EACE,kBAAkB;EAClB,WAAW;EACX,YAAY;EACZ,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,eAAe;EACf,YAAY;AACd","sourcesContent":[".loaderContainer {\n  display: flex;\n  flex-direction: column;\n  position: relative;\n  width: 100%;\n  height: 100vh;\n  justify-content: center;\n  align-items: center;\n  justify-self: flex-start;\n  min-width: 0;\n}\n.loaderContent {\n  display: flex;\n  position: relative;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  width: 100%;\n  height: auto;\n  max-width: 100%;\n  min-width: 0;\n}\n.img {\n  position: relative;\n  object-fit: none;\n  max-width: 100%;\n  margin-top: -150px;\n}\n.img > picture > img {\n  object-fit: none;\n}\n.loadingTexts {\n  display: flex;\n  position: relative;\n  flex-direction: column;\n  align-items: center;\n  justify-content: flex-start;\n  width: 100%;\n  height: auto;\n  max-width: 100%;\n  margin-top: -70px;\n  min-width: 0;\n  padding: 8px;\n}\n.loadingPrimaryText {\n  width: 100%;\n  height: auto;\n  max-width: 100%;\n  text-align: center;\n  font-weight: 700;\n  font-size: 16px;\n  min-width: 0;\n}\n.loadingSecondaryText {\n  position: relative;\n  width: 100%;\n  height: auto;\n  max-width: 100%;\n  text-align: center;\n  font-weight: 700;\n  font-size: 14px;\n  min-width: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loaderContainer": `PlasmicEvoLoader_loaderContainer__dZH3T`,
	"loaderContent": `PlasmicEvoLoader_loaderContent__Yubwl`,
	"img": `PlasmicEvoLoader_img__2+wj5`,
	"loadingTexts": `PlasmicEvoLoader_loadingTexts__3qrz3`,
	"loadingPrimaryText": `PlasmicEvoLoader_loadingPrimaryText__UJSfm`,
	"loadingSecondaryText": `PlasmicEvoLoader_loadingSecondaryText__r9hri`
};
export default ___CSS_LOADER_EXPORT___;
