import React, { cloneElement, isValidElement, ReactNode } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

const EvoFormField = ({ className, children, name }: { className?: string, children: ReactNode, name: string }) => {

    const { control } = useFormContext();

    return (
        <div className={className}>
            {name &&
                <Controller
                    control={control}
                    name={name}
                    render={({ field }) => isValidElement(children) ? cloneElement(children, { ...field })  : <></>}
                />
            }
        </div>
    )

}

export default EvoFormField;