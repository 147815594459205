import { createSlice } from '@reduxjs/toolkit';


export interface LoginData {
    sessionName: string;
    userId: string;
}

export interface User {
    logindata?: LoginData;
    contact?: any;
    id: string;
    first_name?: string;
    last_name?: string;
    gender?: string;
    email1?: string;
    date_format?: string;
    currency_symbol_placement?: string;
    currency_grouping_separator?: string;
    currency_grouping_pattern?: string;
    currency_decimal_separator?: string;
    is_admin?: boolean;
    language?: string;
    profileid?: any;
    roleid?: string;
    rolename?: string;
}

const userInitialState: User = {
    logindata: {
        sessionName: '',
        userId: ''
    },
    contact: null,
    id: '',
    first_name: '',
    last_name: '',
}


export const getSavedUser = () => {
    const currentUser: any = localStorage.getItem('currentUser') ?? null;
    const user: any = currentUser ? JSON.parse(currentUser) : null;
    return user;
}

export const userSlice = createSlice({
    name: 'user',
    initialState: {
        currentUser: userInitialState
    },
    reducers: {
        setUser: (state: any, action: any) => {
            localStorage.setItem('currentUser', JSON.stringify(action.payload));
            state.currentUser = action.payload;
        },
        removeUser: (state: any) => {
            localStorage.removeItem('currentUser');
            state.currentUser = userInitialState;
        },
        logout: (state: any) => {
            localStorage.removeItem('currentUser');
            state.currentUser = userInitialState;
        },
    },
});

export const { setUser, removeUser, logout} = userSlice.actions;
export const getCurrentUser = (state: any) => state?.user?.currentUser;

export const userReducer = userSlice.reducer;